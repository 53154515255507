import { render, staticRenderFns } from "./BlogPostsSection.vue?vue&type=template&id=cb7b2144"
import script from "./BlogPostsSection.vue?vue&type=script&lang=js"
export * from "./BlogPostsSection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlogCard: require('/vercel/path0/components/shared/BlogCard.vue').default})
